<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">明細確認</p>
    </header>
    <section class="modal-card-body">
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <h2 class="is-size-6">
              {{ tBilling.ymLabel }}
            </h2>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="is-flex-direction-column">
              <h2 class="is-size-5">
                <small
                  class="has-text-grey pr-4 is-inline-block"
                  style="width: 7em"
                  >小計</small
                >
                <span class="is-inline-block has-text-right" style="width: 6em"
                  >{{ tBilling.amount.toLocaleString() }}円</span
                >
              </h2>
              <h2 class="is-size-5">
                <small
                  class="has-text-grey pr-4 is-inline-block"
                  style="width: 7em"
                  >源泉徴収税</small
                >
                <span class="is-inline-block has-text-right" style="width: 6em"
                  >{{ tBilling.withholdingAmount.toLocaleString() }}円</span
                >
              </h2>
              <h2 class="is-size-5">
                <small
                  class="has-text-grey pr-4 is-inline-block"
                  style="width: 7em"
                  >合計</small
                >
                <span class="is-inline-block has-text-right" style="width: 6em"
                  >{{ tBilling.taxedAmount.toLocaleString() }}円</span
                >
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="is-relative" v-if="isLoading" style="min-height: 400px">
        <b-loading :is-full-page="false" :active="isLoading" />
      </div>
      <div v-else>
        <b-collapse
          class="card"
          animation="slide"
          v-for="struct of programUnitStructs"
          :key="struct.structKey"
          :open="isOpen.has(struct.structKey)"
          @open="isOpen.add(struct.structKey)"
        >
          <template #trigger="props">
            <div class="card-header" role="button">
              <p class="card-header-title">
                <b-icon icon="bookmark-outline" type="is-info" />
                <span class="pl-3">{{ struct.targetDate.format('YYYY/MM/DD(dd)') }}</span>
              </p>
              <a class="card-header-icon"
                ><strong class=""
                  >¥{{ struct.sumAmount.toLocaleString() }}</strong
                >
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
            </div>
          </template>
          <div class="card-content">
            <div
              class="content"
              v-for="(row, index) in struct.tPaymentRows"
              :key="row.id"
            >
              <hr v-if="index > 0" />
              <table class="table is-bordered is-narrow">
                <tbody>
                  <tr>
                    <td style="width: 6em;">実施施設</td>
                    <td>{{row.facility.label}}</td>
                  </tr>
                  <tr>
                    <td>レッスン</td>
                    <td>{{row.programName}}</td>
                  </tr>
                  <tr>
                    <td>単価 / h</td>
                    <td>¥{{ row.unitAmount.toLocaleString() }}</td>
                  </tr>
                  <tr>
                    <td>出勤時間</td>
                    <td>{{ row.minutes }}分</td>
                  </tr>
                  <tr v-if="row.baseAmount > 0">
                    <td>基本給</td>
                    <td>¥{{ row.baseAmount.toLocaleString() }}</td>
                  </tr>
                  <tr v-if="row.instillAmount > 0">
                    <td>各種手当て</td>
                    <td>¥{{ row.instillAmount.toLocaleString() }}</td>
                  </tr>
                  <tr v-if="row.fixedAmount > 0">
                    <td>固定給</td>
                    <td>¥{{ row.fixedAmount.toLocaleString() }}</td>
                  </tr>
                  <tr v-if="row.amount > 0">
                    <td>小計</td>
                    <td>¥{{ row.amount.toLocaleString() }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-collapse>
        <hr />
        <b-collapse
          class="card"
          animation="slide"
          :open="isOpen.has('bonus')"
          @open="isOpen.add('bonus')"
        >
          <template #trigger="props">
            <div class="card-header" role="button">
              <p class="card-header-title">
                <b-icon icon="home-plus" type="is-info" />
                <span class="pl-3">調整額</span>
              </p>
              <a class="card-header-icon"
                ><strong class=""
                  >¥{{ totalBonusAmount.toLocaleString() }}</strong
                >
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
            </div>
          </template>
          <div class="card-content">
            <div class="content">
              <table class="table is-bordered is-narrow">
                <tbody>
                  <template v-for="(row, idx) in tPaymentBonuses">
                    <tr :key="`head_${row.id}`">
                      <td rowspan="2" style="width:2em">{{ idx+1 }}</td>
                      <td>@{{ row.areaName }}</td>
                      <td class="has-text-right">¥{{ row.amount.toLocaleString() }}</td>
                    </tr>
                    <tr :key="`note_${row.id}`">
                      <td colspan="2">{{ row.note }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </b-collapse>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button label="閉じる" @click="$emit('close')" />
    </footer>
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import { ReceiptPaymentRowStruct, TBilling } from '@/types/typeBilling'
import { mapActions } from 'vuex'
import { ApiPaymentInstructor } from '@/api'
export default {
  mixins: [BaseMixin],
  props: {
    tBilling: TBilling,
  },
  data () {
    return {
      tPaymentRows: [],
      tPaymentBonuses: [],
      isOpen: new Set(),
    }
  },
  computed: {
    programUnitStructs () {
      return Object.values(this.tPaymentRows.reduce((map, row) => {
        if (row.receiptStructKey in map) {
          map[row.receiptStructKey].addRow(row)
        } else {
          map[row.receiptStructKey] = new ReceiptPaymentRowStruct(row.receiptStructKey, row.targetDate).addRow(row)
        }
        return map
      }, {}))
    },
    totalBonusAmount () {
      return this.tPaymentBonuses.reduce((sum, row) => sum + row.amount, 0)
    }
  },
  methods: {
    ...mapActions('storeBilling', ['answerBilling']),
  },
  created () {
    this.isLoading = true
  },
  mounted () {
    ApiPaymentInstructor.readPaymentInstructor(this.tBilling.paymentYmKey).then(({ tPaymentRows, tPaymentBonuses }) => {
      this.tPaymentRows = tPaymentRows
      this.tPaymentBonuses = tPaymentBonuses
      this.isLoading = false
    }).catch(this.handleError)
  },
}
</script>
