<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">請求書確認</p>
    </header>
    <section class="modal-card-body">
      <div class="box">
        <p class="is-clearfix">
          <small class="is-pulled-right">{{
            tBilling.paymentYm.maxDay.format('YYYY年MM月DD日')
          }}</small>
        </p>
        <div class="content">
          <h1 class="has-text-centered mb-5">御請求書</h1>
          <p>株式会社セイカスポーツセンター様</p>
          <p><small class="pr-4">件名</small>レッスン及び講師料</p>
          <p class="mt-5"><small>下記のとおりご請求申し上げます。</small></p>
          <h3 class="has-text-centered" style="">
            <span style="border-bottom: 2px solid black"
              >ご請求金額
              <span class="pl-3"
                >¥{{ tBilling.taxedAmount.toLocaleString() }}</span
              >
            </span>
          </h3>
          <table class="table is-narrow is-bordered is-fullwidth mt-6">
            <thead>
              <tr>
                <td class="has-text-centered">適用</td>
                <td class="has-text-centered" width="30%">時間</td>
                <td class="has-text-centered" width="30%">合計金額</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="has-text-centered">
                  <small>レッスン内容別紙参照</small>
                  <p style="height: 5em"></p>
                </td>
                <td class="has-text-centered">{{ tBilling.timeLabel }}</td>
                <td class="has-text-centered">
                  ¥{{ tBilling.amount.toLocaleString() }}
                </td>
              </tr>
              <tr>
                <td class="has-text-right" colspan="2">小計</td>
                <td class="has-text-centered">
                  ¥{{ tBilling.amount.toLocaleString() }}
                </td>
              </tr>
              <tr>
                <td class="has-text-right" colspan="2">源泉徴収税</td>
                <td class="has-text-centered">
                  ¥{{ tBilling.withholdingAmount.toLocaleString() }}
                </td>
              </tr>
              <tr>
                <td class="has-text-right" colspan="2">合計</td>
                <td class="has-text-centered">
                  ¥{{ tBilling.taxedAmount.toLocaleString() }}
                </td>
              </tr>
            </tbody>
          </table>
          <p class="mt-5"><small>届出口座にお振込ください。</small></p>
        </div>
      </div>
      <div class="has-background-warning px-2 py-2" v-if="!hadBeenOk">
        <form autocomplete="off" class="pt-4">
          <b-field label="" class="has-text-centered">
            <b-checkbox size="is-large" v-model="billingAnswerForm.confirmed"
              >上記内容で請求します。</b-checkbox
            >
          </b-field>
          <b-field label="連絡事項(任意)">
            <b-input
              type="textarea"
              v-model="billingAnswerForm.note"
              maxlength="100"
              :rows="3"
            />
          </b-field>
        </form>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button label="閉じる" :loading="isLoading" @click="$emit('close')" />
      <b-button
        v-if="shoudSave"
        type="is-primary"
        @click.prevent="willSave"
        :loading="isLoading"
        expanded
        :disabled="!shoudSave"
        >送信</b-button
      >
    </footer>
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import { BillingAnswerForm, TBilling } from '@/types/typeBilling'
import { mapActions } from 'vuex'
export default {
  mixins: [BaseMixin],
  props: {
    tBilling: TBilling,
  },
  computed: {
    hadBeenOk () {
      return this.tBilling.answer.is('OK')
    },
    shoudSave () {
      return !this.hadBeenOk && this.billingAnswerForm.confirmed
    }
  },
  data () {
    return {
      billingAnswerForm: new BillingAnswerForm(this.tBilling)
    }
  },
  methods: {
    ...mapActions('storeBilling', ['answerBilling']),
    willSave () {
      console.log('willSave')
      this.isLoading = true
      setTimeout(() => {
        this.answerBilling(this.billingAnswerForm).then(() => {
          this.$emit('saved')
          this.$emit('close')
          this.isLoading = false
        }).catch(this.handleError)
      }, 300)
    },
  }
}
</script>
