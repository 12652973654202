<template>
  <article class="media">
    <figure class="media-left">
      <p class="">
        <b-icon
          size="is-large"
          icon="file-outline"
          v-if="tBilling.answer.is('Init')"
        />
        <b-icon
          size="is-large"
          icon="file-check"
          type="is-success"
          v-else-if="tBilling.answer.is('OK')"
        />
        <b-icon
          size="is-large"
          icon="file-alert-outline"
          type="is-danger"
          v-else-if="tBilling.answer.is('NG')"
        />
      </p>
      <b-button
        type="is-info"
        size="is-small"
        class="mt-2"
        outlined
        @click="willToReceipt"
        >明細</b-button
      >
      <b-button
        v-if="tBilling.answer.is('OK')"
        type="is-info"
        size="is-small"
        class="mt-2"
        outlined
        expanded
        @click="willToAnswer"
        >請求書</b-button
      >
    </figure>
    <div class="media-content">
      <div class="content">
        <p class="media-meta">
          <strong>{{ tBilling.ymLabel }}</strong>
          <small class="has-text-grey">{{ tBilling.rangeLabel }}</small>
        </p>
        <p>
          <small class="has-text-grey pr-4 is-inline-block" style="width: 6em"
            >時間</small
          >{{ tBilling.timeLabel }}
          <br />
          <small class="has-text-grey pr-4 is-inline-block" style="width: 6em"
            >請求金額</small
          >{{ tBilling.taxedAmount.toLocaleString() }}円
          <br />
          <small
            class="has-text-grey pr-4 is-inline-block"
            v-if="tBilling.answer.is('OK')"
            >請求書確認済み</small
          >
        </p>
      </div>
      <nav class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <b-button
              type="is-danger"
              outlined
              icon-left="alert-circle-outline"
              @click="willToAnswer"
              v-if="tBilling.answer.is('Init')"
              >請求書を確認する</b-button
            >
          </div>
        </div>
      </nav>
    </div>
  </article>
</template>

<script>
import { TBilling } from '@/types/typeBilling'
import BillingAnswerFormVue from './BillingAnswerForm.vue'
import baseMixin from '@/baseMixin'
import BillingReceiptVue from './BillingReceipt.vue'
export default {
  mixins: [baseMixin],
  name: 'MediaItem',
  props: {
    tBilling: TBilling
  },
  methods: {
    willToAnswer () {
      console.log('willToAnswer')
      this.$buefy.modal.open({
        parent: this,
        component: BillingAnswerFormVue,
        fullScreen: true,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { tBilling: this.tBilling },
        events: {
          saved: () => {
            console.log('saved')
            this.$buefy.toast.open({
              message: '送信しました',
              position: 'is-top',
              type: 'is-info',
              duration: 4000
            })
          },
        }
      })
    },
    willToReceipt () {
      console.log('willToReceipt')
      this.$buefy.modal.open({
        parent: this,
        component: BillingReceiptVue,
        fullScreen: true,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { tBilling: this.tBilling },
        events: {
        }
      })
    },
  }
}
</script>
