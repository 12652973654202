<template>
  <div>
    <section class="hero is-hero-bar is-purple-hero">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item is-hero-content-item">
              <div style="padding: 0 2em">
                <h1 class="title is-spaced">請求書確認</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="is-relative" v-if="isLoading" style="min-height: 100px">
      <b-loading :is-full-page="false" :active="isLoading" />
    </div>
    <section class="section is-main-section" v-else>
      <b-message type="is-danger" v-if="needAnswerBilling"
        >{{
          needAnswerBilling.ymLabel
        }}の請求金額が確定しました。<br />請求書内容を確認してください。</b-message
      >
      <CardComponent
        title="請求書一覧"
        icon="file-multiple-outline"
        is-scrollable
        :has-button-slot="true"
      >
        <button
          slot="button"
          type="button"
          class="button is-small"
          :class="{ 'is-loading': isLoading }"
          @click.prevent="willForgeDatas"
        >
          <b-icon icon="refresh" custom-size="default" />
          <span>更新</span>
        </button>

        <div class="media-list">
          <b-loading :is-full-page="false" :active="isLoading" />
          <BillingMedia
            v-for="tBilling in tBillings"
            :key="tBilling.id"
            :tBilling="tBilling"
          />
        </div>
      </CardComponent>
    </section>
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import { mapActions, mapState } from 'vuex'
import CardComponent from '@/components/CardComponent.vue'
import BillingMedia from '@/components/Billing/BillingMedia.vue'
export default {
  components: { CardComponent, BillingMedia },
  mixins: [BaseMixin],
  props: {},
  computed: {
    ...mapState('storeBilling', ['tBillings']),
    needAnswerBilling () {
      return this.tBillings.find(tBilling => tBilling.answer.is('Init')) || null
    }
  },
  methods: {
    ...mapActions('storeBilling', ['forgeDatas']),
    willForgeDatas () {
      this.isLoading = true
      setTimeout(() => {
        this.forgeDatas().then(() => {
          this.isLoading = false
        }).catch(this.handleError)
      }, 300)
    },
  },
  created () {
    this.isLoading = true
  },
  mounted () {
    this.willForgeDatas()
  }
}
</script>

<style>
</style>